import React from "react"

import { graphql, PageProps } from "gatsby"

import Layout from "../components/Layouts/mainPage"
import { NewHeader } from "../components/Headers/NewHeader"
import { Banner } from "../components/Banners/BackToSchool"
import { CardWithMaxBenefits } from "../components/CardWithMaxBenefits"
import { FormPKW } from "../components/FormPKW"
import { HowInstallmentWorks } from "../components/HowInstallmentWorks"
import { InstallmentExample } from "../components/InstallmentExample"
import { MoreBenefitsRedisign } from "../components/MorBenefitsRedisgn"
import { NewCalculator } from "../components/NewCalculator/NewCalculator"
import { Partners } from "../components/PartnersNew"
import { Questions } from "../components/Questions"
import { NewFooter } from "../components/NewFooter"

import { PageData } from "../interfaces/pageProps"

import { getPageData } from "../helpers/getPageData"
import { QUESTIONS_FOR_PKW } from "../components/Questions/helpers"

// Время для таймер
const countDownTime = 1800000

const bannerTitle = `
  Готовимся к школе <br/>
  с Халвой!
`

const bannerDescription = `
  — 24 месяца рассрочки у партнёров <br/>
  — 1% от всех покупок перечислим  <br/>
  в благотворительные фонды 
`

const calculatorText =
  "Вы не платите проценты – за вас это делает магазин. Сумма покупки делится на равные платежи – вносите небольшими частями каждый месяц. Получите увеличенный срок рассрочки с акцией «Готовимся к школе с Халвой»! С 1 августа по 10 сентября срок рассрочки увеличивается до 18 месяцев на покупки у всех партнеров."

export default function BackToSchool({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)

  const { halvaOstatok } = data.admin

  if (!halvaOstatok) {
    throw new Error("The halvaOstatok variable is required!!!")
  }

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader showHint={false} hasCTA additionalEventInDataLayer countDownTime={countDownTime} />
      <Banner description={bannerDescription} title={bannerTitle} orderNum="1" />
      <CardWithMaxBenefits orderNum="2" />
      <FormPKW
        dataLayerName="shortPersonalForm"
        withTimer
        countDownTime={countDownTime}
        orderNum="3"
      />
      <HowInstallmentWorks variant="pkw" orderNum="4" />
      <NewCalculator darkColor subtext={calculatorText} titleLocationBottom orderNum="5" />
      <InstallmentExample orderNum="6" />
      <MoreBenefitsRedisign additionalEventInDataLayer orderNum="7" />
      <Partners darkColor orderNum="8" />
      <Questions questionList={QUESTIONS_FOR_PKW} additionalEventInDataLayer orderNum="9" />
      <NewFooter ligal={ligal} orderNum="10" />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/backtoschool/") {
        url
        phones
        ligal {
          text
        }
        notIndex
      }
      halvaOstatok: variable(name: "halvaOstatok") {
        value
      }
    }
  }
`
